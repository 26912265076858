import { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from './MantineProvider';
import { QueryProvider } from './QueryProvider';
import { AuthProvider } from './AuthProvider';
import { LanguageProvider } from './LanguageProvider';
import { Loading } from '@/components/ui/Loading';
import { store } from '@/store';
import moment from 'moment';
import 'moment/dist/locale/pl';

moment.locale('pl');

export const AppProviders = ({ children }: { children: ReactNode }) => {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<MantineProvider>
					<QueryProvider>
						<AuthProvider>
							<LanguageProvider>
								<Suspense fallback={<Loading />}>{children}</Suspense>
							</LanguageProvider>
						</AuthProvider>
					</QueryProvider>
				</MantineProvider>
			</BrowserRouter>
		</Provider>
	);
};
