import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Alert,
	Card,
	CardBody,
	Container,
	FormFeedback,
	Input,
	Label,
	Form,
	CardTitle,
} from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { getErrorMessage, ErrorCode } from '@/types/ErrorCodes';
import { validatePassword } from '@/utils/utilities';
import { LoadingOverlay } from '@mantine/core';
import { SubmitStatus } from './Login';
import { AppPaths } from '@/configs/appPaths';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import logo from '@/assets/images/logo_new.webp';

const initialValues = {
	password: '',
	repeatPassword: '',
};

const SUCCESS_MESSAGE =
	'Hasło zostało zresetowane. Wróć do panelu logowania i zaloguj się za pomocą nowego hasła.';

export const SetNewPassword: React.FC = () => {
	const navigate = useNavigate();

	const token = useParams().token as string;

	useEffect(() => {
		if (!token) navigate(`/login`);
	}, [navigate, token]);

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);
	const [errorMessage, setErrorMessage] = useState('');

	const form = useForm({
		initialValues,
		validate: {
			password: (value: string, values: typeof initialValues) => {
				if (value !== values.repeatPassword)
					return 'Oba hasła muszą być takie same';
				if (!validatePassword(value))
					return getErrorMessage([ErrorCode.INVALID_PASSWORD]);
				return null;
			},
			repeatPassword: (value: string, values: typeof initialValues) => {
				if (value !== values.password) return 'Oba hasła muszą być takie same';
				if (!validatePassword(value))
					return getErrorMessage([ErrorCode.INVALID_PASSWORD]);
				return null;
			},
		},
	});

	const handleSubmit = async () => {
		setStatus(SubmitStatus.LOADING);
		const response = await UnauthenticatedApi.resetPassword(
			token,
			form.values.password
		);
		form.reset();

		if (response === SubmitStatus.SUCCESS) {
			setStatus(SubmitStatus.SUCCESS);
			return;
		}

		setErrorMessage(getErrorMessage(response.message));
		setStatus(SubmitStatus.ERROR);
	};

	return (
		<div className="account-pages">
			<Container>
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card className="overflow-hidden">
							<LoadingOverlay visible={status === SubmitStatus.LOADING} />
							<div className="logo-bar">
								<img alt="logo" src={logo} />
							</div>
							<CardBody>
								<div className="p-2">
									<CardTitle>Utwórz nowe hasło</CardTitle>
									{status === SubmitStatus.ERROR && (
										<Alert color="danger">{errorMessage}</Alert>
									)}
									{status === SubmitStatus.SUCCESS ? (
										<Alert color="success">{SUCCESS_MESSAGE}</Alert>
									) : (
										<p className="card-text">
											Lorem impsum zasady tworzenia hasła: some quick example
											text to build on the card title and make up the bulk of
											the card's content.
										</p>
									)}
									<Form
										className="form-horizontal"
										onSubmit={form.onSubmit(handleSubmit)}
									>
										<div className="mb-3">
											<Label className="form-label">Hasło</Label>
											<Input
												required
												type="password"
												className="form-control"
												placeholder="Wpisz hasło"
												{...form.getInputProps('password')}
												invalid={status === SubmitStatus.ERROR}
												valid={status === SubmitStatus.SUCCESS}
												disabled={status === SubmitStatus.SUCCESS}
											/>
											<FormFeedback type="invalid">
												{form.errors.password}
											</FormFeedback>
											<FormFeedback valid type="valid">
												Podano prawidłowe hasło
											</FormFeedback>
										</div>
										<div className="mb-3">
											<Label className="form-label">Powtórz hasło</Label>
											<Input
												required
												type="password"
												className="form-control"
												placeholder="Wpisz hasło"
												{...form.getInputProps('repeatPassword')}
												invalid={status === SubmitStatus.ERROR}
												valid={status === SubmitStatus.SUCCESS}
												disabled={status === SubmitStatus.SUCCESS}
											/>
											<FormFeedback type="invalid">
												{form.errors.repeatPassword}
											</FormFeedback>
											<FormFeedback valid type="valid">
												Podano prawidłowe hasło
											</FormFeedback>
										</div>
										<Row>
											<Col className="">
												<Link to={`/${AppPaths.LOGIN}`}>
													<button
														className="btn btn-secondary w-md "
														type="button"
													>
														Wróć
													</button>
												</Link>
											</Col>
											<Col className="text-end">
												{status !== SubmitStatus.SUCCESS && (
													<button
														className="btn btn-primary w-md "
														type="submit"
													>
														Resetuj
													</button>
												)}
											</Col>
										</Row>
									</Form>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
