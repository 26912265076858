import React, { useState } from 'react';
import {
	Row,
	Col,
	Alert,
	Card,
	CardBody,
	Container,
	FormFeedback,
	Input,
	Label,
	Form,
	CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { SubmitStatus } from './Login';
import { LoadingOverlay } from '@mantine/core';
import { AppPaths } from '@/configs/appPaths';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import logo from '@/assets/images/logo_new.webp';

const initialValues = {
	email: '',
};

const SUCCESS_MESSAGE =
	'Na twój adres e-mail wysłaliśmy informacje dotyczące resetowania	hasła. Postępuj zgodnie z instrukcjami zawartymi w wiadomości.';

export const ResetPassword: React.FC = () => {
	const form = useForm({
		initialValues,
	});

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);

	const handleSubmit = async () => {
		setStatus(SubmitStatus.LOADING);
		const response = await UnauthenticatedApi.requestResetPassword(
			form.values.email
		);
		form.reset();

		if (response === SubmitStatus.SUCCESS) {
			setStatus(SubmitStatus.SUCCESS);
			return;
		}

		form.setFieldError(
			'email',
			'Nie znaleziono użytkownika o podanym adresie email'
		);
		setStatus(SubmitStatus.ERROR);
	};

	return (
		<div className="account-pages">
			<Container>
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card className="overflow-hidden">
							<LoadingOverlay visible={status === SubmitStatus.LOADING} />
							<div className="logo-bar">
								<img alt="logo" src={logo} />
							</div>
							<CardBody>
								<div className="p-2">
									<CardTitle>Zresetuj hasło</CardTitle>
									{status === SubmitStatus.SUCCESS ? (
										<Alert color="success">{SUCCESS_MESSAGE}</Alert>
									) : (
										<p className="card-text">
											Lorem impsum zasady resetowania hasła: some quick example
											text to build on the card title and make up the bulk of
											the card's content.
										</p>
									)}
									<Form
										className="form-horizontal"
										onSubmit={form.onSubmit(handleSubmit)}
									>
										<div className="mb-3">
											<Label htmlFor="email" className="form-label">
												Email
											</Label>
											<Input
												required
												id="email"
												type="email"
												className="form-control"
												placeholder="Podaj adres email"
												{...form.getInputProps('email')}
												valid={status === SubmitStatus.SUCCESS}
												invalid={status === SubmitStatus.ERROR}
												disabled={status === SubmitStatus.SUCCESS}
											/>
											<FormFeedback valid type="valid">
												Podano prawidłowy adres email
											</FormFeedback>
											<FormFeedback valid={false} type="invalid">
												{form.errors.email}
											</FormFeedback>
										</div>
										<Row>
											<Col className="">
												<Link to={`/${AppPaths.LOGIN}`}>
													<button
														type="button"
														className="btn btn-secondary w-md "
													>
														Wróć
													</button>
												</Link>
											</Col>
											<Col className="text-end">
												{status !== SubmitStatus.SUCCESS && (
													<button
														className="btn btn-primary w-md "
														type="submit"
													>
														Resetuj
													</button>
												)}
											</Col>
										</Row>
									</Form>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
