import { QuestionType } from '@/api/FormTemplateApi';

const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	HEADER_HEIGHT: 70,
	TABLE_PAGE_SIZES: [15, 50, 100],
	DEFAULT_LANGUAGE: 'PL',
	ADMIN_DEFAULT_ROLE_ID: crypto.randomUUID(),
	DESCRIPTION_MAX_LENGTH: 1000,
	SESSION_STORAGE_KEYS: {
		REFRESH_TOKEN: 'refreshToken',
		USERS: {
			PAGE: 'users-page',
			LIMIT: 'users-limit',
			SEARCH: 'users-search',
			SORT: 'users-sort',
		},
		CLIENTS: {
			PAGE: 'clients-page',
			LIMIT: 'clients-limit',
			SEARCH: 'clients-search',
			SORT: 'clients-sort',
		},
		ATTRIBUTE_VALUES: {
			SELECTED_ATTRIBUTE: 'selected-attribute',
		},
	},
	NON_ANSWER_QUESTION_TYPES: [
		QuestionType.ADVANCED_OPTIONS,
		QuestionType.FILES,
		QuestionType.DIMENSIONS,
		QuestionType.TEXTAREA,
		QuestionType.TEXT,
		QuestionType.NUMBER,
	] as QuestionType[],
} as const;

export default APP_CONFIG;
